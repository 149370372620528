.Video{
    background-color: inherit;
    .preview{
        position: relative;
    }
    .media:hover .video-overlay{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 210px;
        height: 118px;
        display: block;
        background-color: rgba(255,255,255,0.5);
        z-index: 999;
        transition: .5s ease;

    }    
    .media .video-overlay .playnow{
        display:none;
    }      
    .media:hover .video-overlay .playnow{
        display:inline-block;
        margin-top: 50px;
    } 

    .no-lib-box{
        padding: 100px;
    }
    
    .no-lib{
        width: 100px;
        height: 100px;
        text-align: center;
        display: block;
        margin:auto;

    }
    .no-lib-text{
        text-align: center;
    }

    .nav.MuiAppBar-colorPrimary{
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%);
    }
    .video-manager-body{
        background-color: #fff;
        padding: 20px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 2px 0px rgb(0 0 0 / 12%);
        border-radius: 4px;
    }
    .title{
        font-size: 1.3rem;
        color: rgba(0,0,0,0.8);
    }

}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }


.container{

    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */


    .frame{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}


.zframe{
    width: calc(75vh * 1.77);
    height: 75vh;
    border: none;
}